@use "../../../assets/scss" as s;

.member-management {
  @include s.flexDirection(column);
  flex: auto 1 0;
  border: 1px solid s.$white;
  border-radius: 1.25rem;
  background-color: s.$white;
  margin: 0 0.4rem 2.5rem 0.4rem;
  padding: 1.5rem 0 0 2rem;
  box-shadow: 2px 5px 15px 5px #0000000d;

  @media only screen and (max-width: 992px) {
    padding: 24px !important;
  }

  &__headers {
    @include s.flex(space-between, center);

    @media only screen and (max-width: 992px) {
      flex-wrap: wrap;
    }
  }

  &__title {
    color: s.$darkGray;
    padding-top: 0.3rem;
    margin: 0;
    font-size: 18px;
    text-decoration: none;

    @media only screen and (max-width: 750px) {
      margin-bottom: 12px;
    }
  }

  &__add-users {
    @include s.flexVerAlignment(center);
    flex-wrap: wrap;
    color: s.$darkPurple;
  }

  &__add-btn {
    display: flex;
    border: none;
    padding: 6px 10px;
    cursor: pointer;
    color: s.$darkPurple;
    border-radius: 4px;
    font-weight: 530;
    text-align: center;

    align-items: center;
    font-size: 16px;
    background-color: s.$white;
    margin-right: 1.5rem;

    @media only screen and (max-width: 992px) {
      padding-left: 0;
    }
  }

  &__add-btn:hover {
    text-decoration: underline;
  }

  &__search-member {
    margin-top: 1rem;
    max-height: 32px;
  }

  &__search-input {
    margin-right: 1.5rem;
    padding: 0.2rem 0.3rem;;
    border-radius: 0.4rem;
    border: 0.0625rem solid #bdc7cf;
    font-style: italic;
    width: 223px;
    height: 30px;
  }

  &__search-input--focus:focus {
    background-color: s.$lightGray;
    //color: transparent !important;
    outline: none;
  }

  &__search-btn {
    padding: 0.3rem 1.5rem;
    border: none;
    border-radius: 0.4rem;
    background-color: s.$white;
    visibility: hidden;
  }

  &__members-info {
    display: flex;
    margin-top: 1rem;
    color: #555555;
    height: 100%;
  }

  &__members-info-mobile {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    color: #555555;
    height: 100%;
  }

  &__outlet--wrapper {
    display: grid;
    width: 100%;
  }

  &__outlet {
    grid-row: 1;
    grid-column: 1;
  }

  &__outlet--hover {
    grid-row: 1;
    grid-column: 1;
  }

  &__csv-modal-title {
    font-family: 'Quicksand', sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 20px;
    line-height: 25px;
    margin: 0;
    color: s.$black;
    text-align: center;
  }

  &__csv-modal-subtitle {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 1rem;
  }

  &__csv-modal-subtitle-upload {
    @media only screen and (max-width: 600px) {
      margin-top: 2rem;
    }
  }

  &__csv-modal-subtitle-content {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 18px;
    line-height: 23px;
    color: s.$darkGray2;
    margin: 0;
    text-align: left;
  }

  &__csv-modal-content {
    margin: 0.5rem 4.2rem;

    @media only screen and (max-width: 600px) {
      margin: 0.5rem 2rem;
    }
  }

  &__csv-modal-content-text {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 18px;
    line-height: 23px;
    text-align: justify;
    margin: 0;

    @media only screen and (max-width: 600px) {
      text-align: left;
      margin-bottom: 24px;
    }
  }

  &__csv-modal-form-control {
    display: flex !important;
    justify-content: center !important;
  }

  &__csv-modal-div {
    // margin: 0 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__buttons-main {
    border-radius: 8px !important;
    box-shadow: 0 4px 4px rgba(53, 48, 48, .1) !important;
    cursor: pointer;
    font-family: "Mulish", sans-serif !important;
    font-style: normal;
    font-size: 1.125rem !important;
    font-weight: 700 !important;
    text-align: center;
    color: #fff !important;
  }

  &__save-btn {
    background-color: s.$lightBlue3 !important;
  }

  &__upload-btn {
    background-color: s.$lightOrange !important;
  }

  &__disabled-btn {
    color: #00000042 !important;
    background-color: #0000001f !important;
    box-shadow: none !important;
  }

  &__csv-modal-download-btn {
    width: 166px;
    height: 39px;
    //margin: 10px 10px 5px;
    border: none;
    border-radius: 8px !important;
    box-shadow: 0 4px 4px rgba(53, 48, 48, .1);
    cursor: pointer;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-size: 18px;
    align-items: stretch;
    text-align: center;
    padding: 8px 27px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: s.$lightBlue3 !important;
    color: #fff !important;
  }

  &__csv-modal-upload-btn {
    width: 136px;
    height: 39px;
    //margin: 10px 10px 5px;
    border: none;
    border-radius: 8px !important;
    box-shadow: 0 4px 4px rgba(53, 48, 48, .1);
    cursor: pointer;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-size: 18px;
    align-items: stretch;
    text-align: center;
    padding: 8px 27px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: s.$lightOrange !important;
    color: #fff !important;
  }

  &__no-members {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 23px;
  }

  &__link-text {
    text-decoration: underline;
    cursor: pointer;
    color: s.$darkPurple;
    font-weight: 530;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }

  &__hubspot-modal {
    min-width: 40rem;
    min-height: 35rem;

    @media (max-width: 700px) {
      min-width: auto;
      min-height: auto;
    }
  }
}
