@use "../../../../assets/scss" as s;

.circular-progress-element {
  height: 45px !important;
  width: 45px !important;
  color: s.$orange !important;
}

.typography-element {
  font-size: 1rem !important;
  font-style: italic;
}

.quick-guide {
  &__modal-padding {
    padding: 1.5rem;

    @media (max-width: 700px) {
      padding: 1rem;
    }
  }

  &__main {
    width: 680px;
    height: 250px;
    border-radius: 8px;
    background-color: rgba(45, 170, 226, 0.05);
    margin-top: 1rem;
    display: flex;
    flex-direction: row;

    @media (max-width: 700px) {
      width: auto;
      height: auto;
      margin-top: 0.3rem;
    }
  }

  &__subtitle {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 1rem;

    @media (max-width: 700px) {
      margin: 0.6rem 0 0.3rem;
    }
  }

  &__paragraph {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    line-height: 25px;
    color: rgba(51, 51, 51, 1);
    margin-left: 0.25rem;
    margin-top: 0.5rem;
    margin-bottom: 0;

    @media (max-width: 700px) {
      line-height: 15px;
      font-size: 14px;
    }
  }

  &__ul-element {
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }

  &__link {
    font-family: 'Mulish', sans-serif;
    font-weight: 300;
    font-style: italic;
    text-decoration: none;
    color: s.$darkPurple;
    margin: 0 0 0 23rem;

    @media (max-width: 700px) {
      margin: 0 0 0 2rem;
      font-size: 14px;
    }
  }

  &__import-section {
    display: flex;

    @media (max-width: 700px) {
      justify-content: center;
      margin-bottom: 0;
    }
  }

  &__learn-more-subtitle {
    font-family: 'Quicksand', sans-serif;
    font-weight: 300 !important;
    font-size: 16px;
    line-height: 20px;
    color: s.$darkPurple;
    margin: 0;
    cursor: pointer;
  }

  &__errors_link{
    text-decoration: underline;
  }

  &__learn-more-div {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__import-div {
    margin-left: 17rem;

    @media (max-width: 700px) {
      margin-left: 0;
    }
  }

  &__import-stats-p {
    @media (max-width: 700px) {
      margin-top: 0.2rem;
      margin-bottom: 0 !important;
    }
  }

  &__token-section {
    @media (max-width: 700px) {
      margin-bottom: 0.3rem;
    }
  }
}